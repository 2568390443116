/* -------FuturaStd-------- */
@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-heavy');
	font-weight: 700;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-heavyOblique');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-Book');
	font-weight: 500;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-BookOblique');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-Medium');
	font-weight: 600;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-MediumOblique');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-Bold');
	font-weight: 800;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-BoldOblique');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-ExtraBold');
	font-weight: 900;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-ExtraBoldOblique');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-Light');
	font-weight: 100;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-Light');
	font-weight: 200;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-Light');
	font-weight: 300;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-Book');
	font-weight: 400;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-LightOblique');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-LightOblique');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-LightOblique');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd';
	src: local('FuturaStd-BookOblique');
	font-weight: 400;
	font-style: italic;
}

/* -------FuturaStd-Thin-------- */
@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedLight');
	font-weight: 100;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedLight');
	font-weight: 200;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedLight');
	font-weight: 300;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedLight');
	font-weight: 400;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedLightObl');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedLightObl');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedLightObl');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedLightObl');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-Condensed');
	font-weight: 500;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedOblique');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedBold');
	font-weight: 600;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedBoldObl');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedBold');
	font-weight: 700;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedBoldObl');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedExtraBd');
	font-weight: 800;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondExtraBoldObl');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondensedExtraBd');
	font-weight: 900;
}

@font-face {
	font-family: 'FuturaStd-Thin';
	src: local('FuturaStd-CondExtraBoldObl');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "linotype-didot-headline";
	src: url("https://use.typekit.net/af/05d1ce/00000000000000007735bbca/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/05d1ce/00000000000000007735bbca/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/05d1ce/00000000000000007735bbca/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "linotype-didot";
	src: url("https://use.typekit.net/af/aec0c1/00000000000000007735bbc9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/aec0c1/00000000000000007735bbc9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/aec0c1/00000000000000007735bbc9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: "linotype-didot";
	src: url("https://use.typekit.net/af/8bae95/00000000000000007735bbd9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/8bae95/00000000000000007735bbd9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/8bae95/00000000000000007735bbd9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 700;
	font-stretch: normal;
}

@font-face {
	font-family: "linotype-didot";
	src: url("https://use.typekit.net/af/cf4868/00000000000000007735bbdc/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/cf4868/00000000000000007735bbdc/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/cf4868/00000000000000007735bbdc/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: "linotype-didot";
	src: url("https://use.typekit.net/af/b00c42/00000000000000007735bbdf/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/b00c42/00000000000000007735bbdf/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/b00c42/00000000000000007735bbdf/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: 'FuturaStd', sans-serif !important;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

/* -------------------------------------------------------------------------- */
/*                              PRIMARY SCROLLBAR                             */
/* -------------------------------------------------------------------------- */
/* width */
.primary-scrollbar::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.primary-scrollbar::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
}

/* Handle */
.primary-scrollbar::-webkit-scrollbar-thumb {
	background: #c85312;
}

/* Handle on hover */
.primary-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #8b3405;
	cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                      -                                     */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                          PRIMARY SCROLLBAR ROUNDED                         */
/* -------------------------------------------------------------------------- */
/* width */
.primary-scrollbar-rounded::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.primary-scrollbar-rounded::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
	border-radius: 10px;
}

/* Handle */
.primary-scrollbar-rounded::-webkit-scrollbar-thumb {
	background: #c85312;
	border-radius: 10px;
}

/* Handle on hover */
.primary-scrollbar-rounded::-webkit-scrollbar-thumb:hover {
	background: #8b3405;
	cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                      -                                     */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                             SECONDARY SCROLLBAR                            */
/* -------------------------------------------------------------------------- */
/* width */
.secondary-scrollbar::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.secondary-scrollbar::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
}

/* Handle */
.secondary-scrollbar::-webkit-scrollbar-thumb {
	background: #041e42;
}

/* Handle on hover */
.secondary-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #021024;
	cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                      -                                     */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                         SECONDARY SCROLLBAR ROUNDED                        */
/* -------------------------------------------------------------------------- */
/* width */
.secondary-scrollbar-rounded::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.secondary-scrollbar-rounded::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
	border-radius: 10px;
}

/* Handle */
.secondary-scrollbar-rounded::-webkit-scrollbar-thumb {
	background: #041e42;
	border-radius: 10px;
}

/* Handle on hover */
.secondary-scrollbar-rounded::-webkit-scrollbar-thumb:hover {
	background: #021024;
	cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                      -                                     */
/* -------------------------------------------------------------------------- */

.ReactModal__Overlay {
	opacity: 0;
	transform: translateY(100px);
	transition: all 300ms ease-in-out;
	z-index: 99999;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
	transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
	opacity: 0;
	transform: translateY(100px);
}

/* ------------------------------ CSS CKEDITOR5 ----------------------------- */
.ck-editor__editable.ck-focused {
	outline: none !important;
	border: 0.5px solid #c4c4c4 !important;
}

.ck-editor__editable ol,
.ck-editor__editable ul {
	padding-left: 35px;
}

.ck.ck-widget__selection-handle {
	display: none !important;
}

.raw-html-embed.ck-widget.ck-widget_with-selection-handle.ck-widget_selected {
	outline-color: transparent !important;
}

.ck-read-only .raw-html-embed__buttons-wrapper {
	display: none !important;
}

.ck-read-only .ck-widget.raw-html-embed.ck-widget_selected:before {
	display: none !important;
}

.ck-read-only .ck-widget.raw-html-embed .raw-html-embed__preview-content {
	border-spacing: 0 !important;
	background-color: rgb(248 248 248) !important;
}

.ck-read-only .ck-widget.raw-html-embed .raw-html-embed__content-wrapper {
	padding: 0 !important;
}

.ck-read-only .raw-html-embed.ck-widget.ck-widget_with-selection-handle.ck-widget_selected {
	margin: 0 !important;
}

.ck-read-only .ck-widget.raw-html-embed:not(.ck-widget_selected):not(:hover) {
	outline: none !important;
}

.ck-read-only .ck-widget.raw-html-embed,
.ck-widget.raw-html-embed .raw-html-embed__preview-content {
	margin: 0 !important;
}

.ck-read-only .ck-widget.raw-html-embed:before {
	content: '' !important;
	background: rgb(248 248 248) !important;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
	z-index: 999999;
}

/* -------------------------------------------------------------------------- */
/*                                 PAGINATION                                 */
/* -------------------------------------------------------------------------- */
.rc-pagination {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	font-size: 14px;
}

.rc-pagination-item {
	border: 1px solid #e5e7eb;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.rc-pagination-item-active {
	background-color: #041e42;
	border: none;
	color: white;
	font-weight: bold;
}

.rc-pagination-item-link {
	width: 30px;
	height: 30px;
	border: 1px solid #e5e7eb;
}

.rc-pagination-prev .rc-pagination-item-link::after {
	content: '<';
}

.rc-pagination-jump-prev .rc-pagination-item-link::after,
.rc-pagination-jump-next .rc-pagination-item-link::after {
	content: '...';
}

.rc-pagination-next .rc-pagination-item-link::after {
	content: '>';
}

.rc-pagination-disabled {
	opacity: 0.5;
	cursor: not-allowed !important;
}

/* ------------------------------ REACT TOOLTIP ----------------------------- */
.__react_component_tooltip.place-bottom {
	margin-top: 0 !important;
}

.__react_component_tooltip.type-dark {
	background-color: #041e42e2;
	border-radius: 4px;
	font-weight: 400;
	padding: 6px 10px;

}

.__react_component_tooltip.type-dark.place-bottom:after {
	border-top-color: #041e42;
	border-top-style: solid;
	border-top-width: 1px;
}

.__react_component_tooltip {
	max-width: 280px;
	white-space: pre-wrap;
}

span.arrow-tooltip {
	border-style: dashed;
	border-color: transparent;
	border-width: 0.2em;
	display: -moz-inline-box;
	display: inline-block;
	font-size: 40px;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: middle;
	width: 0;
	border-bottom-width: 0.15em;
	border-bottom-style: solid;
	border-bottom-color: #041e42c4;
	right: 8.5px;
	bottom: 0.88em;
	position: absolute;
}

/* -------------------------------------------------------------------------- */
/*                                   SWITCH                                   */
/* -------------------------------------------------------------------------- */
.switch input:checked~.switch-content {
	background-color: #c85312;
	transform: translateX(20px);
	transition: all 0.3s ease-in-out;
}

.statistic-packages-popup-table-row:last-child div {
	border-bottom: none;
}